import React, { useEffect, useState } from 'react';
import findaDoctor from '../../../assets/img/find-a-doctor.png';
import medicalStaff from '../../../assets/img/medical-staff.png';
import medicalResearch from '../../../assets/img/435_Medical_color1_initial_04.png';
import annualHealthCheckup from '../../../assets/img/annual-health-checkups.png';
import NathalieWehbe from '../../../assets/img/nathalie.png';
import LudovicGruel from '../../../assets/img/ludovic.png';
import PhilippeRacine from '../../../assets/img/philippe.png';
import picadult from '../../../assets/img/picadult.png';
import pichand from '../../../assets/img/pichand.png';
import picbrain from '../../../assets/img/picbrain.png';
import { LinkedIn } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ContactForm from './ContactForm';

const Curation = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  const [activeQuestion, setActiveQuestion] = useState(null);

  const steps = [
    {
      number: 1,
      title: 'Créer',
      description: 'Créez autant de DOKs que vous souhaitez.',
      image: medicalResearch,
    },
    {
      number: 2,
      title: 'Proposer',
      description: 'Soumettez-les aux experts médicaux.',
      image: medicalStaff,
    },
    {
      number: 3,
      title: 'Evaluer',
      description: 'Suivez le succès de vos DOKs.',
      image: findaDoctor,
    },
  ];

  const teamMembers = [
    {
      name: 'Philippe Racine',
      linkedIn: 'philipperacine',
      image: PhilippeRacine,
    },
    {
      name: 'Ludovic Gruel',
      linkedIn: 'ludovicgruel',
      image: LudovicGruel,
    },
    {
      name: 'Nathalie Wehbe',
      linkedIn: 'nathaliewehbe',
      image: NathalieWehbe,
    },
  ];

  const visionCards = [
    {
      icon: picbrain, // Replace with actual brain+heart icon
      title: 'Expertise.',
      subtitle: 'Confiance.',
    },
    {
      icon: picadult, // Replace with actual person+cloud icon
      title: "L'avenir se ",
      subtitle: 'construit ensemble.',
    },
    {
      icon: pichand, // Replace with actual bird+hands icon
      title: 'Vos libertés.',
      subtitle: 'Nos responsabilités.',
    },
  ];

  const faqs = [
    {
      id: 1,
      question: "Qu'est-ce qu'un DOK?",
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      id: 2,
      question: 'Qui peut être contributeur?',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
  ];

  return (
    <>
      <div className="container py-5">
        {/* Header */}
        <div className="text-center mb-5" id="coment-ca-marche">
          <h2 className="display-6 mb-3">
            Comment ça marche?
            <span className="text-primary ms-2">Simple comme 1,2,3.</span>
          </h2>
        </div>

        {/* Steps */}
        <div className="row g-4 mb-5">
          {steps.map((step) => (
            <div key={step.number} className="col-md-4">
              <div className="card border-0 text-center h-100">
                <div className="card-body d-flex flex-column align-items-center">
                  {/* Title and Number */}
                  <div className="d-flex align-items-center mb-4 gap-2">
                    <div
                      className="bg-primary text-white rounded-circle d-flex align-items-center justify-content-center"
                      style={{
                        width: '32px',
                        height: '32px',
                        fontSize: '14px',
                      }}
                    >
                      {step.number}
                    </div>
                    <h3 className="h5 mb-0">
                      <span className=" border-success border-2 pb-1">
                        {step.title}
                      </span>
                      <div className='decorativeLineGreen1'></div>

                    </h3>
                  </div>

                  {/* Image */}
                  <div className="mb-4">
                    <img
                      src={step.image}
                      alt={`Step ${step.number}`}
                      className="img-fluid rounded"
                      style={{ height: '150px' }}
                    />
                  </div>

                  {/* Description */}
                  <p className="text-muted mb-0">{step.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        {/* FAQ Section */}

        <div className="mt-5 pt-4 bg-light rounded-3 p-4">
          <div className="container">
            <h3 className="text-center mb-4">
              Des questions?
              <span className="text-primary ms-2">Les réponses sont ici.</span>
            </h3>

            <div className="accordion" id="faqAccordion">
              {faqs.map((faq) => (
                <div
                  key={faq.id}
                  className="accordion-item border-0 bg-transparent mb-3"
                >
                  <h4 className="accordion-header">
                    <button
                      className={`accordion-button ${activeQuestion === faq.id ? '' : 'collapsed'} bg-white shadow-sm rounded`}
                      type="button"
                      onClick={() =>
                        setActiveQuestion(
                          activeQuestion === faq.id ? null : faq.id,
                        )
                      }
                    >
                      {faq.question}
                    </button>
                  </h4>
                  <div
                    className={`accordion-collapse collapse ${activeQuestion === faq.id ? 'show' : ''}`}
                  >
                    <div className="accordion-body pt-3">{faq.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Specialists Section */}
        <div className="text-center mb-5 mt-5">
          <h2 className="h3" id="qui-sommes-nous">
            Au départ, <span className="text-success">des spécialistes.</span>
          </h2>

          <div className="row justify-content-center mt-5">
            {teamMembers.map((member) => (
              <div key={member.name} className="col-md-4 col-lg-3 mb-4">
                <div className="text-center">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="img-fluid rounded-circle mb-3"
                    style={{ maxWidth: '120px' }}
                  />
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <LinkedIn /> {/* Replace with LinkedIn icon */}
                    <a
                      href={`https://linkedin.com/in/${member.linkedIn}`}
                      className="text-decoration-none"
                    >
                      {member.name}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div>
        {/* Vision Section */}
        <div className="bg-light py-5 mb-5">
          <div className="container">
            <h2 className="h3 text-center mb-5">
              Autour d'une vision commune.
            </h2>
            <div className="row justify-content-center g-4">
              {visionCards.map((card, index) => (
                <div key={index} className="col-md-4">
                  <div className="card h-100 border-0 bg-white">
                    <div className="card-body d-flex flex-row align-items-center justify-content-between">
                      <div></div>
                      <img
                        src={card.icon}
                        alt=""
                        className="ml-4"
                        style={{ height: '100px' }}
                      />
                    </div>
                    <div className="card-body">
                    <h3 className="h5 mb-2">{card.title}</h3>
                    <h3 className="h5 mb-2">{card.subtitle}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="p-4 p-lg-5 text-center">
              <button
                className="btn btn-light shadow-sm black-outline-btn"
                onClick={() => {
                  navigate('/comingsoon');
                }}
              >
                en savoir plus sur notre éthique​
              </button>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row align-items-center">
            {/* Text Section */}
            <div className="col-md-6">
              <h2 className="text-primary">
                Pour réunir avec les praticiens{' '}
                <span className="text-success">
                  le meilleur de l’information
                </span>
                .
              </h2>
              <p className="text-secondary text-black">
                Dokatis combine les avantages de la curation de contenu et la
                puissance de l’intelligence artificielle.
              </p>
            </div>

            {/* Image Section */}
            <div className="col-md-6">
              <img
                src={annualHealthCheckup}
                alt="Annual Health Checkup"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* Contact Section */}
        <div className="bg-light py-5 mb-5">
          <div className="container" id="nous-contacter">
            <h3 className="text-center mb-4">
              Contactez-nous. 
              <span className="text-primary ms-2">On s’occupe de vous dès maintenant.​</span>
            </h3>
            <ContactForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default Curation;
