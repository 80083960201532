import React from 'react';
import ReactPaginate from 'react-paginate';
//import '../assets/template/css/paginate.css';

export default function PaginatedItems({
  handlePageClick,
  totalPage,
  initialPage,
  className,
}) {
  return (
    <ReactPaginate
      previousLabel={<span aria-hidden="true">«</span>}
      nextLabel={<span aria-hidden="true">»</span>}
      breakLabel={'...'}
      breakLinkClassName={'page-link'}
      pageCount={totalPage}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      initialPage={initialPage - 1}
      onClick={handlePageClick}
      containerClassName={'pagination my-2 justify-content-center'}
      subContainerClassName={'page-link'}
      activeClassName={'page-item active'}
      pageLinkClassName={'page-link'}
      pageClassName={'page-item'}
      nextLinkClassName={'page-link'}
      previousLinkClassName={'page-link'}
      previousClassName={'page-item'}
      nextClassName={'page-item'}
      renderOnZeroPageCount={null}
    />
  );
}
