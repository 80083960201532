import React, { useEffect } from 'react';
import WorkersInHourse from '../../../assets/img/workers-working-in-house.png';

const LegalPage = () => {
  return (
    <>
      <div className="container my-5">
        <h2 className="text-dark text-center mb-5">
          Mentions légales.{' '}
          <span className="text-primary">Bientôt disponibles.</span>
        </h2>

        <div className="row align-items-center">
          {/* Image Section */}
          <div className="col-md-6">
            <img
              src={WorkersInHourse}
              alt="Coming Soon Builders"
              className="img-fluid"
            />
          </div>

          {/* Text Section */}
          <div className="col-md-6">
            <ul className="list-unstyled mt-4">
              <li>• Editeur du site et hébergement</li>
              <li>• Ethique éditoriale</li>
              <li>• Protection des données</li>
              <li>• Utilisation de cookies</li>
              <li>• Conditions d’utilisation</li>
              <li>• Conditions de vente</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalPage;
