import React, { useEffect } from 'react';
import WorkersInHourse from '../../../assets/img/workers-working-in-house.png';

const comingSoon = () => {
  return (
    <>
      <div className="container my-5">
        <h2 className="text-dark text-center mb-5">
          Page.{' '}
          <span className="text-primary">Bientôt disponibles.</span>
        </h2>

        <div className="row align-items-center">
          {/* Image Section */}
          <div className="col-md-12">
            <img
              src={WorkersInHourse}
              alt="Coming Soon Builders"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default comingSoon;
