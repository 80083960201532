import React, { useState } from 'react';
import './ContactForm.css'; // Import the CSS file for styling
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let data = axios
        .post(
          `${process.env.REACT_APP_PUBLIC_URL}/api/contactus/send`,
          formData,
        )
        .then(async ({ data }) => {
          const response = data;
          console.log('Email successfully sent!');
          alert('Email successfully sent!');
          // Clear form
          setFormData({
            name: '',
            email: '',
            subject: '',
            message: '',
          });
          return response;
        })
        .catch((apiError) => {
          if (apiError.response && apiError.response.status === 503) {
            console.error('Error:', apiError);
            alert('Failed to send email. Please try again later.');
          }
          return Promise.reject(apiError);
        });
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send email. Please try again later.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <div className="form-group">
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          placeholder="prénom & nom"
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          placeholder="e-Mail"
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
          placeholder="Objet"
        />
      </div>
      <div className="form-group">
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          placeholder="Message"
        ></textarea>
      </div>
      <div className="p-3 p-lg-3 text-center">
        <button type="submit" className="btn btn-light shadow-sm black-outline-btn" style={{width: '200px'}}>
          Envoyer
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
